import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Layout,
  message,
  Row,
  Typography,
} from "antd";
import { api, axios_error } from "../../../config";
import { useNavigate, useLocation } from "react-router-dom";

const FormItem = Form.Item;

function CompanyForm() {
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const navigate = useNavigate();

  let { state } = useLocation();

  const handleSubmit = useCallback(
    async (val) => {
      try {
        setLoading(true);
        if (state?.id) {
          await api.put(`/admin/company/${state?.id}`, JSON.stringify(val));
          message.success("Company Updated!");
        } else {
          await api.post("/admin/company", JSON.stringify(val));
          message.success("Company Added!");
        }
        setLoading(false);
        navigate(-1);
      } catch (err) {
        axios_error(err);
        setLoading(false);
      }
    },
    [navigate, state]
  );

  useEffect(() => {
    if (form && state?.id) {
      form.setFieldsValue(state);
    }
  }, [form, state]);

  return (
    <Layout.Content>
      <Typography.Title level={3} style={{ textAlign: "center" }}>
        Company Form
      </Typography.Title>

      <Row gutter={16}>
        <Col md={{ offset: 6, span: 12 }} sm={24}>
          <Form layout="vertical" onFinish={handleSubmit} form={form}>
            <FormItem
              label="Company Name"
              name="name"
              rules={[{ required: true, message: "Company name is requried!" }]}
            >
              <Input />
            </FormItem>
            <FormItem
              label="Mobile"
              name="mobile"
              rules={[{ required: true, message: "Mobile is required!" }]}
            >
              <Input />
            </FormItem>
            <FormItem
              label="Email"
              name="email"
              rules={[{ required: true, message: "Email is required!" }]}
            >
              <Input />
            </FormItem>
            <FormItem
              label="Address"
              name="address"
              rules={[{ required: true, message: "Address is required!" }]}
            >
              <Input.TextArea />
            </FormItem>
            <FormItem
              label="City"
              name="city"
              rules={[{ required: true, message: "City is required!" }]}
            >
              <Input />
            </FormItem>
            <FormItem
              label="State"
              name="state"
              rules={[{ required: true, message: "State is required!" }]}
            >
              <Input />
            </FormItem>
            <FormItem
              label="Country"
              name="country"
              rules={[{ required: true, message: "Country is required!" }]}
            >
              <Input />
            </FormItem>
            <FormItem
              label="Pin Code"
              name="pin_code"
              rules={[{ required: true, message: "Pin Code is required!" }]}
            >
              <Input />
            </FormItem>
            <FormItem
              label="Preorder days"
              name="preorder_days"
              rules={[
                { required: true, message: "Pre order days is required!" },
              ]}
              initialValue={1}
            >
              <InputNumber />
            </FormItem>
            <FormItem>
              <Button htmlType="submit" type="primary" loading={loading}>
                Submit
              </Button>
            </FormItem>
          </Form>
        </Col>
      </Row>
    </Layout.Content>
  );
}

export default CompanyForm;

import React from "react";
import { Select } from "antd";

const { Option } = Select;

function CompanyUserRolePicker({ value, onChange, ...props }) {
  return (
    <Select
      placeholder="Company User Role"
      value={value}
      onChange={onChange}
      allowClear
      {...props}
    >
      <Option value="ADMIN">Admin</Option>
      <Option value="EMPLOYEE">Employee</Option>
    </Select>
  );
}

export default CompanyUserRolePicker;

import {
  Button,
  Layout,
  message,
  Popconfirm,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  DeleteFilled,
  PauseCircleFilled,
  PlayCircleFilled,
  PlusOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { api, axios_error } from "../../../config";

function VendorsList() {
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const _get_vendors_list = useCallback(() => {
    setLoading(true);
    api
      .get(`/admin/vendors`)
      .then((res) => {
        setVendors(res.data);
        setLoading(false);
      })
      .catch((err) => {
        axios_error(err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    _get_vendors_list();
  }, [_get_vendors_list]);

  const _delete_vendor = useCallback(
    (r) => {
      api
        .delete(`/admin/vendor/${r.id}`)
        .then((_res) => {
          message.success("Vendor is deleted!");
          _get_vendors_list();
        })
        .catch((err) => {
          axios_error(err);
        });
    },
    [_get_vendors_list]
  );

  const _update_vendor_status = useCallback(
    ({ vendor_id, status }) => {
      api
        .patch(`/admin/vendor/${vendor_id}/status/${status}`)
        .then(() => {
          if (status === 0) {
            message.success("Vendor is disabled!");
          } else {
            message.success("Vendor is enabled!");
          }
          _get_vendors_list();
        })
        .catch(axios_error);
    },
    [_get_vendors_list]
  );

  const columns = useMemo(() => {
    return [
      { title: "ID", dataIndex: "id" },
      {
        title: "Name",
        dataIndex: "name",
        render: (v, r) => <Link to={`/vendor/${r.id}`}>{v}</Link>,
      },
      { title: "Mobile", dataIndex: "mobile" },
      { title: "City", dataIndex: "city" },
      { title: "Pin Code", dataIndex: "pin_code" },
      {
        title: "Status",
        dataIndex: "status",
        render: (v) => {
          if (v === 1) {
            return <Tag color="green">Enabled</Tag>;
          } else if (v === 0) {
            return <Tag color="red">Disabled</Tag>;
          } else {
            return <Tag>Unknown</Tag>;
          }
        },
      },
      {
        title: "Actions",
        render: (r) => (
          <Space>
            {r?.status === 0 ? (
              <PlayCircleFilled
                className="action-icon"
                title="Resume Vendor"
                onClick={_update_vendor_status.bind(this, {
                  vendor_id: r?.id,
                  status: 1,
                })}
              />
            ) : (
              <PauseCircleFilled
                className="action-icon"
                title="Pause Vendor"
                onClick={_update_vendor_status.bind(this, {
                  vendor_id: r?.id,
                  status: 0,
                })}
              />
            )}
            <Popconfirm
              title="Are you sure?"
              onConfirm={_delete_vendor.bind(this, r)}
            >
              <DeleteFilled className="action-icon error" />
            </Popconfirm>
          </Space>
        ),
      },
    ];
  }, [_delete_vendor, _update_vendor_status]);

  return (
    <Layout.Content>
      <Typography.Title level={3}>Vendors</Typography.Title>

      <Table
        columns={columns}
        dataSource={vendors}
        loading={loading}
        rowKey="id"
      />

      <Button
        icon={<PlusOutlined />}
        type="primary"
        shape="circle"
        className="fab"
        onClick={() => navigate("/vendor/new")}
      />
    </Layout.Content>
  );
}

export default VendorsList;

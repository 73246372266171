import { Select } from "antd";
import React from "react";

const { Option } = Select;

export default function ProductTypePicker({ value, onChange }) {
  return (
    <Select
      placeholder="Product Type"
      value={value}
      onChange={onChange}
      allowClear
    >
      <Option value="VEG">Veg</Option>
      <Option value="NON_VEG">Non Veg</Option>
    </Select>
  );
}

import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { baseurl, getToken } from "../../config";

function ImageUploader(props) {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(undefined);

  function beforeUpload(file) {
    let allowed_types = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/webp",
      "image/svg+xml",
      "image/avif",
    ];
    const isJpgOrPng = allowed_types.includes(file.type);
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  }

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(false);
      props.onChange && props.onChange(undefined);
      return;
    }
    if (info.file.status === "done") {
      let res = info.file.response;
      setLoading(false);
      setImageUrl(res.url);
      props.onChange && props.onChange(res.id);
      props.response && props.response(res);
    }
  };

  useEffect(() => {
    setImageUrl(props.imageUrl);
  }, [props.imageUrl]);

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined spin /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <div>
      <Upload
        name="file"
        multiple={false}
        action={`${baseurl}/upload`}
        headers={{ Authorization: `Bearer ${getToken()}` }}
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        beforeUpload={beforeUpload}
        onChange={handleChange}
      >
        {imageUrl ? (
          <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
        ) : (
          uploadButton
        )}
      </Upload>
    </div>
  );
}

export default ImageUploader;

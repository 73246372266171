import { Layout, Typography } from "antd";
import React from "react";

function Dashboard() {
  return (
    <Layout.Content>
      <Typography.Title level={3}>Dashboard</Typography.Title>
    </Layout.Content>
  );
}

export default Dashboard;

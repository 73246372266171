import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { api } from "./config";
import { AdminLayout, CompanyLayout, GuestLayout } from "./layout";

function App() {
  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [role, setRole] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
      let _role = window.localStorage.getItem("role");
      if (!_role) throw Error();
      else setRole(_role);
      if (_role === "ADMIN") {
        let { data } = await api.get("/user");
        dispatch({ type: "SET_USER", payload: data });
      } else {
        let { data } = await api.get("/company/auth");
        dispatch({ type: "SET_USER", payload: data });
      }
      setLoading(false);
      setLoggedIn(true);
    }
    fetchData().catch((_err) => {
      setLoading(false);
      setLoggedIn(false);
    });
  }, [dispatch]);

  if (loading) {
    return <></>;
  } else {
    if (loggedIn) {
      if (role === "ADMIN") return <AdminLayout />;
      else if (role === "COMPANY") return <CompanyLayout />;
      else return <></>;
    } else {
      return <GuestLayout />;
    }
  }
}

export default App;

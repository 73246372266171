import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  message,
  Row,
  Typography,
} from "antd";
import React, { useCallback, useState } from "react";
import { api, axios_error } from "../../config";

const FormItem = Form.Item;

function Profile() {
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const changePassword = useCallback(
    (val) => {
      if (val.old_password === val.new_password) {
        message.error("Current password and new password cannnot be same!");
        return;
      }
      if (val.new_password.length < 8) {
        message.error("New password must be atleast 8 characters!");
        return;
      }
      if (val.new_password !== val.confirm_password) {
        message.error("New password and confirm password do not match!");
        return;
      }
      setLoading(true);
      api
        .post("/change-password", JSON.stringify(val))
        .then((res) => {
          message.success("Password changed!");
          form.resetFields();
          setLoading(false);
        })
        .catch((err) => {
          axios_error(err);
          setLoading(false);
        });
    },
    [form]
  );

  return (
    <Layout.Content>
      <Typography.Title level={3}>User Profile</Typography.Title>

      <Row gutter={16}>
        <Col xl={8} lg={12} md={12} sm={24}>
          <Card title="Change Password">
            <Form layout="vertical" onFinish={changePassword} form={form}>
              <FormItem
                label="Current Password"
                name="old_password"
                rules={[
                  { required: true, message: "Current password is required!" },
                ]}
              >
                <Input.Password />
              </FormItem>
              <FormItem
                label="New Password"
                name="new_password"
                rules={[
                  { required: true, message: "New password is required!" },
                ]}
              >
                <Input.Password />
              </FormItem>
              <FormItem
                label="Confirm Password"
                name="confirm_password"
                rules={[
                  { required: true, message: "Confirm password is required!" },
                ]}
              >
                <Input.Password />
              </FormItem>
              <FormItem>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Update
                </Button>
              </FormItem>
            </Form>
          </Card>
        </Col>
      </Row>
    </Layout.Content>
  );
}

export default Profile;

import React, { useEffect } from "react";

function Logout() {
  useEffect(() => {
    window.localStorage.clear();
    window.sessionStorage.clear();
    window.location.replace("/");
  }, []);

  return <div>Logout</div>;
}

export default Logout;

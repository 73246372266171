import { Select } from "antd";
import React from "react";

const { Option } = Select;

export default function ProductVariantPicker({
  value,
  onChange,
  mode = "multiple",
  style = {},
}) {
  return (
    <Select
      placeholder="Product Variant"
      value={value}
      onChange={onChange}
      mode={mode}
      style={style}
      allowClear
    >
      <Option value="BREAKFAST">Breakfast</Option>
      <Option value="LUNCH">Lunch</Option>
      <Option value="SNACKS">Snacks</Option>
      <Option value="DINNER">Dinner</Option>
    </Select>
  );
}

import { Form, Input, Modal } from "antd";
import React, { useCallback, useState } from "react";
import { api, axios_error } from "../../../config";

const FormItem = Form.Item;

function VendorUserForm({ visible, close, vendor_id }) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);
      let val = await form.validateFields();
      await api.post(`/admin/vendor/${vendor_id}/user`, JSON.stringify(val));
      setLoading(false);
      form.resetFields();
      close(true);
    } catch (err) {
      axios_error(err);
      setLoading(false);
    }
  }, [form, vendor_id, close]);

  return (
    <Modal
      title="Vendor User"
      visible={visible}
      onOk={handleSubmit}
      onCancel={close.bind(this, false)}
      confirmLoading={loading}
    >
      <Form form={form} layout="vertical">
        <FormItem
          label="Name"
          name="name"
          rules={[{ required: true, message: "User's Name is requried!" }]}
        >
          <Input />
        </FormItem>
        <FormItem
          label="Email"
          name="email"
          rules={[{ required: true, message: "Email is required!" }]}
        >
          <Input />
        </FormItem>
        <FormItem label="Mobile" name="mobile">
          <Input />
        </FormItem>
      </Form>
    </Modal>
  );
}

export default VendorUserForm;

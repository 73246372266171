import React, { useCallback, useEffect, useState } from "react";
import { Select } from "antd";
import { api } from "../../config";

const { Option } = Select;

function VendorPicker({ value, onChange }) {
  const [vendors, setVendors] = useState([]);

  const _get_vendors = useCallback(() => {
    api
      .get(`/admin/vendors`)
      .then((res) => {
        setVendors(res.data);
      })
      .catch((_err) => {});
  }, []);

  useEffect(() => {
    _get_vendors();
  }, [_get_vendors]);

  return (
    <Select placeholder="Select Vendor" value={value} onChange={onChange}>
      {vendors.map((item) => {
        return <Option value={item.id}>{item.name}</Option>;
      })}
    </Select>
  );
}

export default VendorPicker;

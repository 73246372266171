import { Card, Col, Layout, Row, Statistic, Typography } from "antd";
import React, { useState, useCallback, useEffect } from "react";
import { api, axios_error } from "../../../config";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";

function Dashboard() {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const _get_dashboard_data = useCallback(() => {
    setLoading(true);
    api
      .get("/admin/dashboard")
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        axios_error(err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    _get_dashboard_data();
  }, [_get_dashboard_data]);

  return (
    <Layout.Content>
      <Typography.Title level={3}>Dashboard</Typography.Title>

      <Row gutter={[12, 12]}>
        <Col sm={6} xs={12}>
          <Card
            loading={loading}
            className="pointer"
            onClick={() => navigate("/companies")}
          >
            <Statistic title="Companies" value={data?.card?.companies} />
          </Card>
        </Col>
        <Col sm={6} xs={12}>
          <Card
            loading={loading}
            className="pointer"
            onClick={() => navigate("/vendors")}
          >
            <Statistic title="Vendors" value={data?.card?.vendors} />
          </Card>
        </Col>
        <Col sm={6} xs={12}>
          <Card
            loading={loading}
            className="pointer"
            onClick={() => navigate("/users")}
          >
            <Statistic
              title="Users"
              value={data?.card?.users}
              suffix={
                data?.card?.new_users !== 0 && (
                  <span
                    style={{
                      fontSize: 14,
                      marginLeft: 4,
                      color: data?.card?.new_users > 0 ? "green" : "red",
                    }}
                  >
                    {data?.card?.new_users > 0 ? (
                      <ArrowUpOutlined />
                    ) : (
                      <ArrowDownOutlined />
                    )}
                    {data?.card?.new_users}
                  </span>
                )
              }
            />
          </Card>
        </Col>
        <Col sm={6} xs={12}>
          <Card
            loading={loading}
            className="pointer"
            onClick={() => navigate("/products")}
          >
            <Statistic title="Products" value={data?.card?.products} />
          </Card>
        </Col>
        <Col sm={6} xs={12}>
          <Card
            loading={loading}
            className="pointer"
            onClick={() => navigate("/orders")}
          >
            <Statistic title="Today's Orders" value={data?.orders?.today} />
          </Card>
        </Col>
        <Col sm={6} xs={12}>
          <Card loading={loading}>
            <Statistic title="This Week's Orders" value={data?.orders?.week} />
          </Card>
        </Col>
        <Col sm={6} xs={12}>
          <Card loading={loading}>
            <Statistic title="Total" value={data?.orders?.total} />
          </Card>
        </Col>
      </Row>
    </Layout.Content>
  );
}

export default Dashboard;

import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  message,
  Row,
  Typography,
} from "antd";
import { api, axios_error } from "../../../config";
import { useNavigate, useLocation, useParams } from "react-router-dom";

const FormItem = Form.Item;

function VendorForm() {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const { state } = useLocation();
  const { id } = useParams();

  const navigate = useNavigate();

  const handleSubmit = useCallback(
    async (val) => {
      try {
        setLoading(true);
        if (id) {
          await api.put(`/admin/vendor/${id}`, JSON.stringify(val));
          message.success("Vendor info updated!");
        } else {
          await api.post("/admin/vendor", JSON.stringify(val));
          message.success("Vendor Added");
        }
        setLoading(false);
        navigate("/vendors");
      } catch (err) {
        axios_error(err);
        setLoading(false);
      }
    },
    [navigate, id]
  );

  useEffect(() => {
    if (form && state) {
      form.setFieldsValue(state);
    }
  }, [form, state]);

  return (
    <Layout.Content>
      <Typography.Title level={3} style={{ textAlign: "center" }}>
        Vendor Form
      </Typography.Title>

      <Row gutter={16}>
        <Col md={{ offset: 6, span: 12 }} sm={24}>
          <Form layout="vertical" onFinish={handleSubmit} form={form}>
            <FormItem
              label="Vendor Name"
              name="name"
              rules={[{ required: true, message: "Name is required!" }]}
            >
              <Input />
            </FormItem>
            <FormItem
              label="Registered Name"
              name="registered_name"
              rules={[
                { required: true, message: "Registered Name is required!" },
              ]}
            >
              <Input />
            </FormItem>
            <FormItem
              label="GSTIN"
              name="gstin"
              rules={[{ required: true, message: "GSTIN is required!" }]}
            >
              <Input />
            </FormItem>
            <FormItem
              label="Mobile"
              name="mobile"
              rules={[{ required: true, message: "Mobile is required!" }]}
            >
              <Input />
            </FormItem>
            <FormItem
              label="Email"
              name="email"
              rules={[{ required: true, message: "Email is required!" }]}
            >
              <Input />
            </FormItem>
            <FormItem
              label="Address"
              name="address"
              rules={[{ required: true, message: "Address is required!" }]}
            >
              <Input.TextArea />
            </FormItem>
            <FormItem
              label="City"
              name="city"
              rules={[{ required: true, message: "City is required!" }]}
            >
              <Input />
            </FormItem>
            <FormItem
              label="State"
              name="state"
              rules={[{ required: true, message: "State is required!" }]}
            >
              <Input />
            </FormItem>
            <FormItem
              label="Country"
              name="country"
              rules={[{ required: true, message: "Country is required!" }]}
            >
              <Input />
            </FormItem>
            <FormItem
              label="Pin Code"
              name="pin_code"
              rules={[{ required: true, message: "Pin code is required!" }]}
            >
              <Input />
            </FormItem>
            <FormItem>
              <Button htmlType="submit" type="primary" loading={loading}>
                Submit
              </Button>
            </FormItem>
          </Form>
        </Col>
      </Row>
    </Layout.Content>
  );
}

export default VendorForm;

import {
  DashboardFilled,
  DropboxOutlined,
  GiftOutlined,
  MailOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Dropdown, Layout, Menu, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CompanyRouter } from "../pages/company";

const { Header, Content, Footer, Sider } = Layout;

const SIDER_WIDTH = 240;

const UserMenu = () => (
  <Menu>
    <Menu.Item>
      <Link to={"/profile"}>Profile</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to={"/logout"}>Logout</Link>
    </Menu.Item>
  </Menu>
);

function CompanyLayout() {
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    document.title = "BizEat Company Panel";
  }, []);

  return (
    <Layout hasSider>
      <Sider
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
        width={SIDER_WIDTH}
        trigger={null}
        collapsible={true}
        collapsed={collapsed}
        onCollapse={setCollapsed}
        collapsedWidth={0}
      >
        <div
          style={{
            height: 64,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography.Title level={3} style={{ color: "white", margin: 0 }}>
            BizEat
          </Typography.Title>
        </div>
        <Menu mode="inline" theme="dark">
          <Menu.Item key={"dashboard"} icon={<DashboardFilled />}>
            <Link to={"/"}>Dashboard</Link>
          </Menu.Item>
          <Menu.Item key={"products"} icon={<DropboxOutlined />}>
            <Link to={"/products"}>Products</Link>
          </Menu.Item>
          <Menu.Item key={"orders"} icon={<GiftOutlined />}>
            <Link to={"/orders"}>Orders</Link>
          </Menu.Item>
          <Menu.Item key={"users"} icon={<UserOutlined />}>
            <Link to={"/users"}>Users</Link>
          </Menu.Item>
          <Menu.Item key={"contact"} icon={<MailOutlined />}>
            <Link to={"/contact"}>Contact</Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout
        style={{ marginLeft: collapsed ? 0 : SIDER_WIDTH }}
        className="main-layout"
      >
        <Header
          style={{ display: "flex", alignItems: "center", color: "white" }}
        >
          {collapsed ? (
            <MenuUnfoldOutlined
              style={{ fontSize: 16, marginLeft: -32, marginRight: 32 }}
              onClick={() => setCollapsed(false)}
            />
          ) : (
            <MenuFoldOutlined
              style={{ fontSize: 16, marginLeft: -32, marginRight: 32 }}
              onClick={() => setCollapsed(true)}
            />
          )}
          <Typography.Title
            level={2}
            style={{ color: "white", margin: 0, flex: 1 }}
          >
            Company Login
          </Typography.Title>
          <Dropdown
            overlay={UserMenu}
            placement="bottomRight"
            trigger={["click"]}
          >
            <Avatar
              shape="square"
              icon={<UserOutlined />}
              style={{ backgroundColor: "#87d068", cursor: "pointer" }}
            />
          </Dropdown>
        </Header>
        <Content style={{ minHeight: "100vh", padding: 24 }}>
          <CompanyRouter />
        </Content>
        <Footer style={{ backgroundColor: "#1A1A1A" }}>
          <p style={{ textAlign: "center", margin: 0, color: "white" }}>
            &copy; BizEat
          </p>
        </Footer>
      </Layout>
    </Layout>
  );
}

export default CompanyLayout;

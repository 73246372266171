import { DeleteFilled, InfoCircleFilled } from "@ant-design/icons";
import { Layout, message, Popconfirm, Space, Table, Typography } from "antd";
import React from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { useMemo } from "react";
import { api, axios_error } from "../../../config";
import AdminTicketViewerModal from "./AdminTicketViewerModal";

function SupportTicketsList() {
  const [tickets, setTickets] = useState([]);
  const [ticket, setTicket] = useState({});
  const [loading, setLoading] = useState(false);

  const __get_tickets_list = useCallback(() => {
    setLoading(true);
    api
      .get("/admin/tickets")
      .then((res) => {
        setTickets(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        axios_error(err);
      });
  }, []);

  const __delete_ticket = useCallback(
    (r) => {
      api
        .delete(`/admin/ticket/${r.id}`)
        .then((res) => {
          message.success("Support ticket deleted!");
          __get_tickets_list();
        })
        .catch((err) => {
          axios_error(err);
        });
    },
    [__get_tickets_list]
  );

  useEffect(() => {
    __get_tickets_list();
  }, [__get_tickets_list]);

  const columns = useMemo(() => {
    return [
      { title: "ID", dataIndex: "id" },
      { title: "Title", dataIndex: "title" },
      { title: "Comapny", dataIndex: "company" },
      { title: "User", dataIndex: "employee_name" },
      { title: "Ticket Status", dataIndex: "ticket_status" },
      { title: "Timestamp", dataIndex: "timestamp" },
      {
        title: "Actions",
        render: (r) => (
          <Space>
            <InfoCircleFilled
              className="action-icon"
              onClick={() => setTicket(r)}
            />
            <Popconfirm
              title="Are you sure?"
              onConfirm={__delete_ticket.bind(this, r)}
            >
              <DeleteFilled className="action-icon error" />
            </Popconfirm>
          </Space>
        ),
      },
    ];
  }, [__delete_ticket]);

  return (
    <Layout.Content>
      <Typography.Title level={3}>Support Ticket</Typography.Title>
      <Table
        columns={columns}
        dataSource={tickets}
        loading={loading}
        rowKey="id"
      />
      <AdminTicketViewerModal
        visible={ticket?.id}
        close={(refresh = false) => {
          setTicket({});
          if (refresh) {
            __get_tickets_list();
          }
        }}
        ticket={ticket}
      />
    </Layout.Content>
  );
}

export default SupportTicketsList;

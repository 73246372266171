import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Col,
  Layout,
  message,
  Popconfirm,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import {
  DeleteFilled,
  EditFilled,
  OrderedListOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { api, axios_error } from "../../../config";
import CompanyUserForm from "./CompanyUserForm";
import { useNavigate } from "react-router-dom";

function UsersList() {
  const [users, setUsers] = useState([]);
  const [spinning, showSpinning] = useState(false);
  const [userForm, showUserForm] = useState(false);
  const [userInfo, setUserInfo] = useState({});

  const navigate = useNavigate();

  const getUsersList = useCallback(() => {
    showSpinning(true);
    api
      .get("/company/users")
      .then((res) => {
        setUsers(res.data);
        showSpinning(false);
      })
      .catch((err) => {
        axios_error(err);
        showSpinning(false);
      });
  }, []);

  useEffect(() => {
    getUsersList();
  }, [getUsersList]);

  const handleDeleteUser = useCallback(
    (id) => {
      api
        .delete(`/company/user/${id}`)
        .then((_res) => {
          message.success("Company user deleted!");
          getUsersList();
        })
        .catch((err) => {
          axios_error(err);
        });
    },
    [getUsersList]
  );

  const columns = useMemo(() => {
    return [
      { title: "ID", dataIndex: "id" },
      { title: "Name", dataIndex: "name" },
      { title: "Email", dataIndex: "email" },
      { title: "Mobile", dataIndex: "mobile" },
      { title: "Employee ID", dataIndex: "employee_id" },
      {
        title: "Actions",
        render: (r) => (
          <Space>
            <EditFilled
              className="action-icon"
              title="Edit user info"
              onClick={() => {
                setUserInfo(r);
                showUserForm(true);
              }}
            />
            <OrderedListOutlined
              className="action-icon"
              title="User Food Preferences"
              onClick={() => navigate(`/user/${r.id}/preferences`)}
            />
            <Popconfirm
              title="Are you sure?"
              onConfirm={handleDeleteUser.bind(this, r.id)}
            >
              <DeleteFilled className="action-icon error" title="Delete User" />
            </Popconfirm>
          </Space>
        ),
      },
    ];
  }, [handleDeleteUser, navigate]);

  const closeModal = useCallback(
    (refresh = false) => {
      if (refresh) {
        getUsersList();
      }
      showUserForm(false);
      setUserInfo({});
    },
    [getUsersList]
  );

  return (
    <Layout.Content>
      <Row gutter={12}>
        <Col md={12}>
          <Typography.Title level={3}>Users List</Typography.Title>
        </Col>
        <Col md={12} className="align-right">
          <Button icon={<UploadOutlined />} type="primary" disabled>
            Upload
          </Button>
        </Col>
      </Row>

      <Table columns={columns} dataSource={users} loading={spinning} />

      <Button
        icon={<PlusOutlined />}
        type="primary"
        shape="circle"
        className="fab"
        onClick={() => showUserForm(true)}
      />

      <CompanyUserForm
        visible={userForm}
        close={closeModal}
        userInfo={userInfo}
      />
    </Layout.Content>
  );
}

export default UsersList;

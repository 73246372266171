import { Modal, Form, Input, message, Row, Col } from "antd";
import React, { useState, useEffect } from "react";
import { ImageUploader } from "../../../components";
import { api, axios_error } from "../../../config";

const FormItem = Form.Item;

export default function CategoryForm({ visible, close, category }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const _handle_submit = async () => {
    setLoading(true);
    try {
      let data = await form.validateFields();
      if (category && category.id) {
        await api.put(`/admin/category/${category.id}`, JSON.stringify(data));
        message.success("Category Updated!");
      } else {
        await api.post("/admin/category", JSON.stringify(data));
        message.success("Category Added");
      }
      form.resetFields();
      setLoading(false);
      close(true);
    } catch (err) {
      axios_error(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (form) {
      if (category && category.id) {
        form.setFieldsValue(category);
      } else {
        form.resetFields();
      }
    }
  }, [category, form]);

  return (
    <Modal
      title="Category Form"
      visible={visible}
      onOk={_handle_submit}
      onCancel={close.bind(this, false)}
      confirmLoading={loading}
    >
      <Form form={form} layout="vertical">
        <FormItem
          label="Category Name"
          name="name"
          rules={[{ required: true, message: "Category name is required!" }]}
        >
          <Input />
        </FormItem>
        <Row gutter={8}>
          <Col sm={12}>
            <FormItem name="image" label="Image">
              <ImageUploader imageUrl={category && category.image_url} />
            </FormItem>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

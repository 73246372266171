import { Layout, Form, Typography, Button, Input, Radio, message } from "antd";
import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { api, axios_error } from "../../config";

const FormItem = Form.Item;

function Login() {
  const [mode, setMode] = useState("company");
  const [otpVisible, setOtpVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState({});

  const admin_login = useCallback((val) => {
    api
      .post("/login", JSON.stringify(val))
      .then((res) => {
        let { access_token } = res.data;
        window.localStorage.setItem("token", access_token);
        window.localStorage.setItem("role", "ADMIN");
        window.location.reload();
      })
      .catch((err) => {
        axios_error(err);
      });
  }, []);

  const company_login = useCallback(
    (val) => {
      setLoading(true);
      let data = { ...otp, otp: val.otp };
      api
        .post("/company/verify-otp", JSON.stringify(data))
        .then((res) => {
          let { access_token } = res.data;
          window.localStorage.setItem("token", access_token);
          window.localStorage.setItem("role", "COMPANY");
          message.success("Logged in!");
          window.location.reload();
          setLoading(false);
        })
        .catch((err) => {
          axios_error(err);
          setLoading(false);
        });
    },
    [otp]
  );

  const request_otp = useCallback((val) => {
    setLoading(true);
    api
      .post("/company/login", JSON.stringify(val))
      .then((res) => {
        setOtp(res.data);
        setLoading(false);
        setOtpVisible(true);
      })
      .catch((err) => {
        axios_error(err);
        setLoading(false);
      });
  }, []);

  const handleSubmit = useCallback(
    (val) => {
      if (mode === "admin") {
        admin_login(val);
      } else if (mode === "company") {
        if (otpVisible) {
          company_login(val);
        } else {
          request_otp(val);
        }
      } else {
        message.error("Incorrect Login Mode Selected!");
      }
    },
    [mode, admin_login, company_login, request_otp, otpVisible]
  );

  return (
    <Layout.Content
      style={{
        minHeight: "50vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          maxWidth: "100%",
          width: 400,
          padding: 32,
          borderRadius: 4,
        }}
      >
        <Typography.Title style={{ textAlign: "center" }}>
          Login
        </Typography.Title>
        <Form layout="vertical" onFinish={handleSubmit}>
          <div style={{ marginBottom: 20, textAlign: "center" }}>
            <Radio.Group value={mode} onChange={(e) => setMode(e.target.value)}>
              <Radio value="admin">Admin</Radio>
              <Radio value="company">Company</Radio>
            </Radio.Group>
          </div>
          <FormItem
            label="Email"
            name="email"
            rules={[{ required: true, message: "Username is required!" }]}
          >
            <Input disabled={otpVisible} />
          </FormItem>
          {mode === "admin" && (
            <FormItem
              label="Password"
              name="password"
              rules={[{ required: true, message: "Password is required!" }]}
            >
              <Input.Password />
            </FormItem>
          )}
          {mode === "admin" && (
            <div style={{ textAlign: "right" }}>
              <Link to="/forgot-password">Forgot Password?</Link>
            </div>
          )}
          {otpVisible && (
            <FormItem
              label="OTP"
              name="otp"
              rules={[{ required: true, message: "OTP is required!" }]}
            >
              <Input />
            </FormItem>
          )}
          {otpVisible && (
            <div style={{ textAlign: "right" }}>
              {/* <Button type="link">Resend OTP</Button>
              <Button type="link">Change Email</Button> */}
            </div>
          )}
          <FormItem>
            <Button htmlType="submit" type="primary" loading={loading}>
              {mode === "company"
                ? otpVisible
                  ? "Login"
                  : "Send OTP"
                : "Login"}
            </Button>
          </FormItem>
        </Form>
      </div>
    </Layout.Content>
  );
}

export default Login;

import React from "react";
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Select,
  Space,
  Typography,
} from "antd";
import { useCallback } from "react";
import { useState } from "react";
import { api, axios_error } from "../../../config";

const { Option } = Select;

export default function AdminTicketViewerModal({ visible, close, ticket }) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);
      let data = await form.validateFields();
      await api.patch(`/admin/ticket/${ticket?.id}`, JSON.stringify(data));
      setLoading(false);
      message.success("Replied to ticket");
      form.resetFields();
      close(true);
    } catch (err) {
      setLoading(false);
      axios_error(err);
    }
  }, [ticket, form, close]);

  return (
    <Modal
      title="Support Ticket Details"
      visible={visible}
      footer={
        <Space>
          <Button onClick={() => close()}>Close</Button>
          {ticket?.reply === null && (
            <Button type="primary" loading={loading} onClick={handleSubmit}>
              Reply
            </Button>
          )}
        </Space>
      }
    >
      <div style={{ marginBottom: 16 }}>
        <Typography.Title level={4} style={{ marginBottom: 0 }}>
          Title
        </Typography.Title>
        <Typography.Paragraph>{ticket?.title}</Typography.Paragraph>
      </div>
      <div style={{ marginBottom: 16 }}>
        <Typography.Title level={5} style={{ marginBottom: 0 }}>
          Timestamp
        </Typography.Title>
        <Typography.Paragraph>{ticket?.timestamp}</Typography.Paragraph>
      </div>
      <div style={{ marginBottom: 16 }}>
        <Typography.Title level={5} style={{ marginBottom: 0 }}>
          Company Name
        </Typography.Title>
        <Typography.Paragraph>{ticket?.company}</Typography.Paragraph>
      </div>
      <div style={{ marginBottom: 16 }}>
        <Typography.Title level={5} style={{ marginBottom: 0 }}>
          Employee Name
        </Typography.Title>
        <Typography.Paragraph>{ticket?.employee_name}</Typography.Paragraph>
      </div>
      <div style={{ marginBottom: 16 }}>
        <Typography.Title level={4} style={{ marginBottom: 0 }}>
          Details
        </Typography.Title>
        <Typography.Paragraph>{ticket?.description}</Typography.Paragraph>
      </div>
      <div style={{ marginBottom: 16 }}>
        <Typography.Title level={4} style={{ marginBottom: 0 }}>
          Reply <small>{ticket?.reply_timestamp}</small>
        </Typography.Title>
        {ticket.reply !== null ? (
          <Typography.Paragraph>{ticket?.reply || " - "}</Typography.Paragraph>
        ) : (
          <div style={{ marginTop: 12 }}>
            <Form layout="vertical" form={form}>
              <Form.Item name="reply" label="Reply">
                <Input.TextArea />
              </Form.Item>
              <Form.Item name="ticket_status" label="Ticket Status">
                <Select>
                  <Option value="RESOLVED">Resolved</Option>
                  <Option value="CLOSED">Closed</Option>
                </Select>
              </Form.Item>
            </Form>
          </div>
        )}
      </div>
    </Modal>
  );
}

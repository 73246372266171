import { Select } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { api } from "../../config";

const { Option } = Select;

export default function CategoryPicker({ value, onChange }) {
  const [categories, setCategories] = useState([]);

  const get_categories = useCallback(() => {
    api
      .get(`/admin/categories`)
      .then((res) => {
        setCategories(res.data);
      })
      .catch((_err) => {});
  }, []);

  useEffect(() => {
    get_categories();
  }, [get_categories]);

  return (
    <Select
      placeholder="Categories"
      value={value}
      onChange={onChange}
      allowClear
    >
      {categories.map((item) => (
        <Option value={item.id} key={item.id}>
          {item.name}
        </Option>
      ))}
    </Select>
  );
}

import { Upload, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { baseurl, getToken } from "../../config";

function MultipleImageUploader({ value, onChange, onRemove }) {
  const [fileList, setFileList] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewTitle, setPreviewTitle] = useState("");
  const [previewImage, setPreviewImage] = useState("");

  const handleChange = ({ fileList }) => {
    setFileList(fileList);
    let res = fileList.map((item) => {
      return item.response || item;
    });
    onChange && onChange(res);
  };

  const handlePreview = (file) => {
    let res = file.response || file;
    setPreviewTitle(res.name);
    setPreviewImage(res.url);
    setPreviewVisible(true);
  };

  const handleCancel = () => setPreviewVisible(false);

  useEffect(() => {
    let files =
      Array.isArray(value) &&
      value.map((item) => {
        item.uid = (item.id || item.file_id || item.uid).toString();
        return item;
      });
    setFileList(files || []);
  }, [value]);

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <div>
      <Upload
        name="file"
        action={`${baseurl}/upload`}
        headers={{ Authorization: `Bearer ${getToken()}` }}
        listType="picture-card"
        fileList={fileList}
        onChange={handleChange}
        onPreview={handlePreview}
        onRemove={onRemove}
        multiple={true}
      >
        {fileList.length >= 8 ? null : uploadButton}
      </Upload>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </div>
  );
}

export default MultipleImageUploader;

import React, { useCallback, useState } from "react";
import { Form, Modal, TimePicker } from "antd";
import { WeekdayPicker } from "../../../components/pickers";
import { ProductTypePicker, ProductVariantPicker } from "../../../components";
import { api, axios_error } from "../../../config";

const FormItem = Form.Item;

function CompanyPlanForm({ visible, close, company_id }) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);
      let val = await form.validateFields();
      val.cutoff_time = val.cutoff_time.format('HH:mm:ss');
      await api.post(`/admin/company/${company_id}/plan`, JSON.stringify(val));
      setLoading(false);
      form.resetFields();
      close(true);
    } catch (err) {
      setLoading(false);
      axios_error(err);
    }
  }, [form, company_id, close]);

  return (
    <Modal
      title="Company Plan Form"
      visible={visible}
      onOk={handleSubmit}
      onCancel={close.bind(this, false)}
      confirmLoading={loading}
    >
      <Form layout="vertical" form={form}>
        <FormItem
          label="Weekday"
          name="weekday"
          rules={[{ required: true, message: "Weekday is required!" }]}
        >
          <WeekdayPicker />
        </FormItem>
        <FormItem
          label="Product Variants"
          name="product_variant"
          rules={[{ required: true, message: "Product variant is required!" }]}
        >
          <ProductVariantPicker mode={null} />
        </FormItem>
        <FormItem
          label="Product Type"
          name="product_type"
          rules={[{ required: true, message: "Product type is required!" }]}
        >
          <ProductTypePicker />
        </FormItem>
        <FormItem
          label="Cutoff Time"
          name="cutoff_time"
          rules={[{ required: true, message: "Cutoff time is required!" }]}
        >
          <TimePicker minuteStep={10} format="hh:mm" />
        </FormItem>
      </Form>
    </Modal>
  );
}

export default CompanyPlanForm;

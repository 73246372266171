import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  Col,
  Descriptions,
  Layout,
  message,
  Popconfirm,
  Row,
  Space,
  Table,
} from "antd";
import { useParams } from "react-router-dom";
import { api, axios_error } from "../../../config";
import CompanyPlanForm from "./CompanyPlanForm";
import {
  DeleteFilled,
  PlayCircleFilled,
  PauseCircleFilled,
} from "@ant-design/icons";
import CompanyUserForm from "./CompanyUserForm";
import CompanyVendorForm from "./CompanyVendorForm";
import { useNavigate } from "react-router-dom";

const { Item } = Descriptions;

function CompanyDetails() {
  const [company, setCompany] = useState({});
  const [plans, setPlans] = useState([]);
  const [users, setUsers] = useState([]);
  const [vendors, setVendors] = useState([]);

  const [loading, setLoading] = useState(false);
  const [planForm, showPlanForm] = useState(false);
  const [userForm, showUserForm] = useState(false);
  const [vendorForm, showVendorForm] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  const get_company_info = useCallback(() => {
    setLoading(true);
    api
      .get(`/admin/company/${id}`)
      .then((res) => {
        let { company, plans, users, vendors } = res.data;
        setCompany(company);
        setPlans(plans);
        setUsers(users);
        setVendors(vendors);
        setLoading(false);
      })
      .catch((err) => {
        axios_error(err);
        setLoading(false);
      });
  }, [id]);

  useEffect(() => {
    get_company_info();
  }, [get_company_info]);

  const closeModal = useCallback(
    (refresh = false) => {
      if (refresh) {
        get_company_info();
      }
      showPlanForm(false);
      showUserForm(false);
      showVendorForm(false);
    },
    [get_company_info]
  );

  const toggleAvailability = useCallback(
    ({ id, state }) => {
      let data = { available: state };
      api
        .patch(`/admin/company/plan/${id}`, JSON.stringify(data))
        .then((_res) => {
          message.success("Availability Updated!");
          get_company_info();
        })
        .catch((err) => {
          axios_error(err);
        });
    },
    [get_company_info]
  );

  const remove_company_user = useCallback(
    (id) => {
      api
        .delete(`/admin/company/user/${id}`)
        .then((_res) => {
          message.success("Company admin removed!");
          get_company_info();
        })
        .catch((err) => {
          axios_error(err);
        });
    },
    [get_company_info]
  );

  const remove_company_vendor = useCallback(
    (id) => {
      api
        .delete(`/admin/company/vendor/${id}`)
        .then((_res) => {
          message.success("Vendor removed from company!");
          get_company_info();
        })
        .catch((err) => {
          axios_error(err);
        });
    },
    [get_company_info]
  );

  const columns = useMemo(() => {
    return [
      { title: "ID", dataIndex: "id" },
      { title: "Day", dataIndex: "weekday" },
      { title: "Product Variant", dataIndex: "product_variant" },
      { title: "Product Type", dataIndex: "product_type" },
      { title: "Cutoff Time", dataIndex: "cutoff_time" },
      {
        title: "Availability",
        dataIndex: "available",
        render: (v) => <span>{v ? "Yes" : "No"}</span>,
      },
      {
        title: "Actions",
        render: (r) => (
          <Space>
            {r.available ? (
              <PauseCircleFilled
                className="action-icon"
                onClick={toggleAvailability.bind(this, {
                  id: r.id,
                  state: false,
                })}
              />
            ) : (
              <PlayCircleFilled
                className="action-icon"
                onClick={toggleAvailability.bind(this, {
                  id: r.id,
                  state: true,
                })}
              />
            )}
            <DeleteFilled className="action-icon error" />
          </Space>
        ),
      },
    ];
  }, [toggleAvailability]);

  const vendor_columns = useMemo(() => {
    return [
      { title: "ID", dataIndex: "id" },
      { title: "Name", dataIndex: "name" },
      {
        title: "Actions",
        render: (r) => (
          <Space>
            <Popconfirm
              title="Are you sure?"
              onConfirm={remove_company_vendor.bind(this, r.id)}
            >
              <DeleteFilled className="action-icon error" />
            </Popconfirm>
          </Space>
        ),
      },
    ];
  }, [remove_company_vendor]);

  const user_columns = useMemo(() => {
    return [
      { title: "ID", dataIndex: "id" },
      { title: "Name", dataIndex: "name" },
      { title: "Mobile", dataIndex: "mobile" },
      { title: "Email", dataIndex: "email" },
      {
        title: "Actions",
        render: (r) => (
          <Space>
            <Popconfirm
              title="Are you sure?"
              onConfirm={remove_company_user.bind(this, r.id)}
            >
              <DeleteFilled className="action-icon error" />
            </Popconfirm>
          </Space>
        ),
      },
    ];
  }, [remove_company_user]);

  return (
    <Layout.Content>
      <Card
        title="Company Details"
        style={{ marginBottom: 16 }}
        loading={loading}
      >
        <Descriptions>
          <Item label="Company Name">{company.name}</Item>
          <Item label="Mobile">{company.mobile}</Item>
          <Item label="Email">{company.email}</Item>
          <Item label="Address">{company.address}</Item>
          <Item label="City">{company.city}</Item>
          <Item label="State">{company.state}</Item>
          <Item label="Pin Code">{company.pin_code}</Item>
          <Item label="Date Added">{company.timestamp}</Item>
          <Item label="Preorder Days">{company.preorder_days}</Item>
        </Descriptions>
      </Card>

      <Row gutter={16}>
        <Col md={12} sm={24}>
          <Card
            title="Vendors"
            loading={loading}
            style={{ marginBottom: 16 }}
            extra={
              <Space>
                <Button
                  type="primary"
                  size="small"
                  onClick={() => showVendorForm(true)}
                >
                  Add
                </Button>
              </Space>
            }
          >
            <Table columns={vendor_columns} dataSource={vendors} rowKey="id" />
          </Card>
        </Col>
        <Col md={12} sm={24}>
          <Card
            title="Company Admin"
            loading={loading}
            style={{ marginBottom: 16 }}
            extra={
              <Space>
                <Button
                  type="primary"
                  size="small"
                  onClick={() => navigate(`/company/${id}/users`)}
                  ghost
                >
                  Users
                </Button>
                <Button
                  type="primary"
                  size="small"
                  onClick={() => showUserForm(true)}
                >
                  Add
                </Button>
              </Space>
            }
          >
            <Table columns={user_columns} dataSource={users} rowKey="id" />
          </Card>
        </Col>
      </Row>

      <Card
        title="Company Plans"
        loading={loading}
        extra={
          <Space>
            <Button
              type="primary"
              size="small"
              onClick={() => showPlanForm(true)}
            >
              Add
            </Button>
          </Space>
        }
      >
        <Table columns={columns} dataSource={plans} rowKey="id" />
      </Card>

      <CompanyPlanForm
        visible={planForm}
        close={closeModal}
        company_id={company.id}
      />

      <CompanyUserForm
        visible={userForm}
        close={closeModal}
        company_id={company.id}
      />

      <CompanyVendorForm
        visible={vendorForm}
        close={closeModal}
        company_id={company.id}
      />
    </Layout.Content>
  );
}

export default CompanyDetails;

import React from "react";
import { Route, Routes } from "react-router";
import { OrdersList } from "./orders";
import { ProductsList } from "./products";
import { CompanyUserPlan, UsersList } from "./users";
import { Logout } from "../guest";
import { Dashboard } from "./dashboard";
import { MessagesList } from "./contact";

function CompanyRouter() {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/products" element={<ProductsList />} />

      <Route path="/orders" element={<OrdersList />} />

      <Route path="/users" element={<UsersList />} />
      <Route path="/user/:userId/preferences" element={<CompanyUserPlan />} />

      <Route path="/contact" element={<MessagesList />} />

      <Route path="/logout" element={<Logout />} />
    </Routes>
  );
}

export { CompanyRouter };

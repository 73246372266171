import React from "react";
import { Select } from "antd";

const { Option } = Select;

function WeekdayPicker({ value, onChange }) {
  return (
    <Select placeholder="Weekday" value={value} onChange={onChange} allowClear>
      <Option value="SUNDAY">SUNDAY</Option>
      <Option value="MONDAY">MONDAY</Option>
      <Option value="TUESDAY">TUESDAY</Option>
      <Option value="WEDNESDAY">WEDNESDAY</Option>
      <Option value="THURSDAY">THURSDAY</Option>
      <Option value="FRIDAY">FRIDAY</Option>
      <Option value="SATURDAY">SATURDAY</Option>
    </Select>
  );
}

export default WeekdayPicker;

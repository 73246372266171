import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  Layout,
  message,
  Row,
  Space,
  Typography,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import {
  CategoryPicker,
  ImageUploader,
  ProductTypePicker,
  ProductVariantPicker,
} from "../../../components";
import { api, axios_error } from "../../../config";
import { VendorPicker } from "../../../components/pickers";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const FormItem = Form.Item;

export default function ProductForm() {
  const [saveProductLoading, setSaveProductLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [form] = Form.useForm();

  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();

  const saveProduct = useCallback(
    async (val) => {
      console.log(val);
      setSaveProductLoading(true);
      try {
        if (id) {
          await api.put(`/admin/product/${id}`, JSON.stringify(val));
          message.success("Product Updated!");
        } else {
          await api.post("/admin/product", JSON.stringify(val));
          message.success("Product Added!");
        }
        setSaveProductLoading(false);
        navigate(-1);
      } catch (err) {
        axios_error(err);
        setSaveProductLoading(false);
      }
    },
    [navigate, id]
  );

  useEffect(() => {
    if (form && state?.id) {
      form.setFieldsValue(state);
      setImageUrl(state?.image_url);
    }
  }, [form, state]);

  return (
    <Layout.Content>
      <Typography.Title level={3}>New Product</Typography.Title>

      <Row gutter={12}>
        <Col md={24} lg={12}>
          <Form layout="vertical" onFinish={saveProduct} form={form}>
            <Card title="Product Info">
              <FormItem
                label="Product Name"
                name="name"
                rules={[
                  { required: true, message: "Product name is required!" },
                ]}
              >
                <Input />
              </FormItem>
              <FormItem
                label="Product Code"
                name="code"
                rules={[
                  { required: true, message: "Product code is requried!" },
                ]}
              >
                <Input />
              </FormItem>
              <FormItem
                label="Vendor"
                name="vendor_id"
                rules={[{ required: true, message: "Vendor is required!" }]}
              >
                <VendorPicker />
              </FormItem>
              <FormItem
                label="Featured Image"
                name="featured_image"
                rules={[
                  { required: true, message: "Featured image is required!" },
                ]}
              >
                <ImageUploader imageUrl={imageUrl} />
              </FormItem>
              <FormItem
                label="Category"
                name="category_id"
                rules={[{ required: true, message: "Category is required!" }]}
              >
                <CategoryPicker />
              </FormItem>
              <FormItem
                label="Product Type"
                name="product_type"
                rules={[
                  { required: true, message: "Product type is required!" },
                ]}
              >
                <ProductTypePicker />
              </FormItem>

              {!state?.id && (
                <Card style={{ marginBottom: 16 }} title="Product Variants">
                  <Form.List
                    name="product_variants"
                    rules={[
                      {
                        validator: async (_, names) => {
                          if (!names || names?.length < 1) {
                            return Promise.reject(
                              new Error(
                                "At least one product variant is required!"
                              )
                            );
                          }
                        },
                      },
                    ]}
                  >
                    {(fields, { add, remove }, { errors }) => (
                      <>
                        {fields.map(({ key, name, ...resetField }) => (
                          <Space
                            key={key}
                            style={{ display: "flex", marginBottom: 8 }}
                            align="center"
                          >
                            <Form.Item
                              label="Product Variant"
                              name={[name, "product_variant"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Product Variant is required!",
                                },
                              ]}
                            >
                              <ProductVariantPicker
                                mode="single"
                                style={{ minWidth: 160 }}
                              />
                            </Form.Item>
                            <FormItem
                              label="Max Quantity"
                              name={[name, "max_quantity"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Max Quantity is required",
                                },
                                {
                                  min: 1,
                                  type: "number",
                                  message: "Must be at least 1",
                                },
                              ]}
                            >
                              <InputNumber />
                            </FormItem>
                            <MinusCircleOutlined onClick={() => remove(name)} />
                          </Space>
                        ))}
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            Add Field
                          </Button>
                        </Form.Item>
                        <Form.ErrorList errors={errors} />
                      </>
                    )}
                  </Form.List>
                </Card>
              )}

              <FormItem
                label="Description"
                name="description"
                rules={[
                  { required: true, message: "Description is required!" },
                ]}
              >
                <Input.TextArea />
              </FormItem>
              <FormItem>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={saveProductLoading}
                >
                  Save
                </Button>
              </FormItem>
            </Card>
          </Form>
        </Col>
      </Row>
    </Layout.Content>
  );
}

import React, { useCallback, useEffect, useState } from "react";
import { Form, Input, message, Modal } from "antd";
import { api, axios_error } from "../../../config";
import { CompanyUserRolePicker } from "../../../components/pickers";

const FormItem = Form.Item;

function CompanyUserForm({ visible, close, userInfo }) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);
      let data = await form.validateFields();
      if (userInfo?.id) {
        await api.put(`/company/user/${userInfo?.id}`, JSON.stringify(data));
        message.success("User info updated!");
      } else {
        await api.post("/company/user", JSON.stringify(data));
        message.success("New user added!");
      }
      setLoading(false);
      form.resetFields();
      close(true);
    } catch (err) {
      axios_error(err);
      setLoading(false);
    }
  }, [form, close, userInfo]);

  useEffect(() => {
    if (form && userInfo?.id) {
      form.setFieldsValue(userInfo);
    } else {
      form.resetFields();
    }
  }, [userInfo, form]);

  return (
    <Modal
      title="Company User Form"
      visible={visible}
      onOk={handleSubmit}
      onCancel={() => close(false)}
      confirmLoading={loading}
    >
      <Form layout="vertical" form={form}>
        <FormItem
          label="Name"
          name="name"
          rules={[{ required: true, message: "Name is required!" }]}
        >
          <Input />
        </FormItem>
        <FormItem
          label="Email"
          name="email"
          rules={[{ required: true, message: "Email is required!" }]}
        >
          <Input />
        </FormItem>
        <FormItem label="Mobile" name="mobile">
          <Input />
        </FormItem>
        <FormItem
          label="Employee ID"
          name="employee_id"
          rules={[{ required: true, message: "Employee ID is required!" }]}
        >
          <Input />
        </FormItem>
        <FormItem
          label="User Role"
          name="company_user_role"
          rules={[
            { required: true, message: "Company User Role is required!" },
          ]}
        >
          <CompanyUserRolePicker />
        </FormItem>
      </Form>
    </Modal>
  );
}

export default CompanyUserForm;

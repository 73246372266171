import { EditFilled } from "@ant-design/icons";
import {
  Button,
  Card,
  Descriptions,
  Layout,
  message,
  Modal,
  Space,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { api, axios_error } from "../../../config";
import VendorUserForm from "./VendorUserForm";

const { Item } = Descriptions;

function VendorDetails() {
  const [vendor, setVendor] = useState({});
  const [loading, setLoading] = useState(false);

  const [vendorUserForm, showVendorUserForm] = useState(false);

  const { id } = useParams();

  const navigate = useNavigate();

  const __get_vendor_details = useCallback(() => {
    setLoading(true);
    api
      .get(`/admin/vendor/${id}`)
      .then((res) => {
        setVendor(res.data);
        setLoading(false);
      })
      .catch((err) => {
        axios_error(err);
        setLoading(false);
      });
  }, [id]);

  useEffect(() => {
    __get_vendor_details();
  }, [__get_vendor_details]);

  const __remove_vendor_user = useCallback(
    (item) => {
      Modal.confirm({
        title: "Are you sure?",
        okType: "danger",
        okText: "Yes",
        cancelText: "No",
        onOk: () => {
          api
            .delete(`/admin/vendor/${vendor?.id}/user/${item?.id}`)
            .then((res) => {
              message.success("Vendor user removed!");
              __get_vendor_details();
            })
            .catch((err) => {
              axios_error(err);
            });
        },
        onCancel: () => {},
      });
    },
    [vendor, __get_vendor_details]
  );

  return (
    <Layout.Content>
      <Card
        loading={loading}
        title="Vendor Details"
        style={{ marginBottom: 16 }}
        extra={
          <Button
            type="primary"
            size="small"
            shape="round"
            icon={<EditFilled />}
            onClick={() => navigate(`/vendor/${id}/edit`, { state: vendor })}
          >
            Edit
          </Button>
        }
      >
        <Descriptions>
          <Item label="Vendor Name">{vendor?.name}</Item>
          <Item label="Registered Name">{vendor?.registered_name}</Item>
          <Item label="GST">{vendor?.gstin}</Item>
          <Item label="Address" span={2}>
            {vendor?.address}
          </Item>
          <Item label="City">{vendor?.city}</Item>
          <Item label="State">{vendor?.state}</Item>
          <Item label="Country">{vendor?.country}</Item>
          <Item label="Pin Code">{vendor?.pin_code}</Item>
          <Item label="Email">{vendor?.email}</Item>
          <Item label="Mobile" span={2}>
            {vendor?.mobile}
          </Item>
          <Item label="Location">{`${vendor?.lat || " - "}, ${
            vendor?.lon || " - "
          }`}</Item>
        </Descriptions>
      </Card>
      <Card
        loading={loading}
        title="Vendor Users"
        extra={
          <Space>
            <Button
              type="primary"
              size="small"
              shape="round"
              onClick={() => showVendorUserForm(true)}
            >
              Add
            </Button>
          </Space>
        }
      >
        {Array.isArray(vendor?.users) &&
          vendor.users.map((item, index) => {
            return (
              <Card key={index} style={{ marginBottom: 8 }}>
                <Descriptions
                  title={item?.name}
                  extra={
                    <Space>
                      <Button
                        type="primary"
                        size="small"
                        shape="round"
                        danger
                        onClick={__remove_vendor_user.bind(this, item)}
                      >
                        Remove
                      </Button>
                    </Space>
                  }
                >
                  <Item label="Name">{item?.name}</Item>
                  <Item label="Email">{item?.email}</Item>
                  <Item label="Mobile">{item?.mobile || " - "}</Item>
                  <Item label="Role">{item?.role}</Item>
                  <Item label="Registration Date">
                    {item?.registration_date}
                  </Item>
                </Descriptions>
              </Card>
            );
          })}
      </Card>
      <VendorUserForm
        visible={vendorUserForm}
        vendor_id={vendor?.id}
        close={(refresh = false) => {
          if (refresh) {
            __get_vendor_details();
          }
          showVendorUserForm(false);
        }}
      />
    </Layout.Content>
  );
}

export default VendorDetails;

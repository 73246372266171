import { DeleteFilled, EditFilled, PauseCircleFilled } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Descriptions,
  Layout,
  Popconfirm,
  Row,
  Space,
  Table,
} from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { api, axios_error } from "../../../config";

const { Item } = Descriptions;

function ProductDetails() {
  const [product, setProduct] = useState({});
  const { id } = useParams();

  const navigate = useNavigate();

  const _get_product_details = useCallback(() => {
    api
      .get(`/admin/product/${id}`)
      .then((res) => {
        setProduct(res.data);
      })
      .catch((err) => {
        axios_error(err);
      });
  }, [id]);

  useEffect(() => {
    _get_product_details();
  }, [_get_product_details]);

  const columns = useMemo(
    () => [
      { title: "ID", dataIndex: "id" },
      { title: "Variant", dataIndex: "product_variant" },
      { title: "Max Quantity", dataIndex: "max_quantity" },
      {
        title: "Actions",
        render: (r) => (
          <Space>
            <PauseCircleFilled className="action-icon" />
            <EditFilled className="action-icon" />
            <Popconfirm title="Are you sure?">
              <DeleteFilled className="action-icon" />
            </Popconfirm>
          </Space>
        ),
      },
    ],
    []
  );

  return (
    <Layout.Content>
      <Card
        title="Product Details"
        extra={
          <Button
            type="primary"
            size="small"
            shape="round"
            icon={<EditFilled />}
            onClick={() => navigate(`/product/${id}/edit`, { state: product })}
          >
            Edit
          </Button>
        }
        style={{ marginBottom: 16 }}
      >
        <Descriptions>
          <Item label="Name">{product?.name}</Item>
          <Item label="Description" span={2}>
            {product?.description}
          </Item>
          <Item label="Code">{product?.code}</Item>
          <Item label="Vendor">{product?.vendor}</Item>
          <Item label="Product Type">{product?.product_type}</Item>
          <Item label="Cusine (Category)">{product?.category}</Item>
        </Descriptions>
      </Card>
      <Row>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Card title="Variants">
            <Table
              columns={columns}
              dataSource={product?.variants}
              rowKey={"id"}
              pagination={false}
            />
          </Card>
        </Col>
      </Row>
    </Layout.Content>
  );
}

export default ProductDetails;

import {
  Layout,
  Table,
  Typography,
  Button,
  Space,
  Popconfirm,
  message,
} from "antd";
import {
  DeleteFilled,
  InfoCircleFilled,
  PlusOutlined,
} from "@ant-design/icons";
import React, { useCallback, useEffect, useState } from "react";
import { useMemo } from "react";
import TicketFormModal from "./TicketFormModal";
import { api, axios_error } from "../../../config";
import TicketViewerModal from "./TicketViewerModal";

function MessagesList() {
  const [tickets, setTickets] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [ticket, setTicket] = useState({});
  const [loading, setLoading] = useState(false);

  const __get_tickets_list = useCallback(() => {
    setLoading(true);
    api
      .get("/company/tickets")
      .then((res) => {
        setTickets(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        axios_error(err);
      });
  }, []);

  useEffect(() => {
    __get_tickets_list();
  }, [__get_tickets_list]);

  const __delete_ticket = useCallback(
    (r) => {
      api
        .delete(`/company/ticket/${r.id}`)
        .then((res) => {
          message.success("Support ticket deleted!");
          __get_tickets_list();
        })
        .catch((err) => {
          axios_error(err);
        });
    },
    [__get_tickets_list]
  );

  const columns = useMemo(() => {
    return [
      { title: "ID", dataIndex: "id" },
      { title: "Title", dataIndex: "title" },
      { title: "Ticket Status", dataIndex: "ticket_status" },
      { title: "Timestamp", dataIndex: "timestamp" },
      {
        title: "Actions",
        render: (r) => (
          <Space>
            <InfoCircleFilled
              className="action-icon"
              onClick={() => setTicket(r)}
            />
            <Popconfirm title="Are you sure?" onConfirm={__delete_ticket.bind(this, r)}>
              <DeleteFilled className="action-icon error" />
            </Popconfirm>
          </Space>
        ),
      },
    ];
  }, [__delete_ticket]);

  return (
    <Layout.Content>
      <Typography.Title level={3}>Contact</Typography.Title>

      <Table
        columns={columns}
        dataSource={tickets}
        rowKey="id"
        loading={loading}
      />

      <Button
        icon={<PlusOutlined />}
        shape="circle"
        type="primary"
        className="fab"
        onClick={() => setModalVisible(true)}
      />

      <TicketFormModal
        visible={modalVisible}
        close={(refresh) => {
          if (refresh) {
            __get_tickets_list();
          }
          setModalVisible(false);
        }}
      />

      <TicketViewerModal
        visible={ticket?.id}
        close={() => setTicket({})}
        ticket={ticket}
      />
    </Layout.Content>
  );
}

export default MessagesList;

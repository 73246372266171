import { message } from "antd";
import axios from "axios";

const baseurl = process.env.REACT_APP_API_BASE_URL;

function getToken() {
  var token =
    window.localStorage.getItem("token") ||
    window.sessionStorage.getItem("token");
  if (!token) {
    return false;
  }
  return token;
}

var api = axios.create({
  baseURL: baseurl,
  headers: {
    Authorization: "Bearer " + getToken(),
    "Content-Type": "application/json",
  },
});

const axios_error = (err) => {
  let details = err.response && err.response.data && err.response.data.detail;
  let statusText = err.response && err.response.statusText;
  const msg = typeof details === "string" ? details : statusText || err.message;
  message.error(msg);
};

export { baseurl, getToken, api, axios_error };

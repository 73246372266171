import React, { useCallback, useState } from "react";
import { Form, Input, message, Modal } from "antd";
import { api, axios_error } from "../../../config";

const FormItem = Form.Item;

export default function TicketFormModal({ visible, close }) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    try {
      let val = await form.validateFields();
      await api.post("/company/ticket", JSON.stringify(val));
      message.success("New support request raised!");
      setLoading(false);
      form.resetFields();
      close(true);
    } catch (err) {
      axios_error(err);
      setLoading(false);
    }
  }, [form, close]);

  return (
    <Modal
      title="New Support Ticket"
      visible={visible}
      onOk={handleSubmit}
      onCancel={() => close(false)}
      confirmLoading={loading}
    >
      <Form layout="vertical" form={form}>
        <FormItem
          label="Title"
          name="title"
          rules={[{ required: true, message: "Title is required!" }]}
        >
          <Input />
        </FormItem>
        <FormItem
          label="Details"
          name="description"
          rules={[{ required: true, message: "Details is required!" }]}
        >
          <Input.TextArea />
        </FormItem>
      </Form>
    </Modal>
  );
}

import {
  Button,
  Layout,
  message,
  Popconfirm,
  Space,
  Table,
  Typography,
} from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DeleteFilled, PlusOutlined, EditOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { api, axios_error } from "../../../config";

function CompaniesList() {
  const [companies, setCompanies] = useState([]);
  const [spinning, setSpinning] = useState(false);

  const navigate = useNavigate();

  const _get_companies = useCallback(() => {
    setSpinning(true);
    api
      .get("/admin/companies")
      .then((res) => {
        setCompanies(res.data);
        setSpinning(false);
      })
      .catch((err) => {
        axios_error(err);
        setSpinning(false);
      });
  }, []);

  useEffect(() => {
    _get_companies();
  }, [_get_companies]);

  const _delete_company = useCallback(
    (r) => {
      api
        .delete(`/admin/company/${r.id}`)
        .then((_res) => {
          message.success("Company Deleted!");
          _get_companies();
        })
        .catch((err) => {
          axios_error(err);
        });
    },
    [_get_companies]
  );

  const columns = useMemo(() => {
    return [
      { title: "ID", dataIndex: "id" },
      {
        title: "Name",
        dataIndex: "name",
        render: (v, r) => <Link to={`/company/${r.id}`}>{v}</Link>,
      },
      { title: "City", dataIndex: "city" },
      { title: "Pin Code", dataIndex: "pin_code" },
      {
        title: "Actions",
        render: (r) => (
          <Space>
            <EditOutlined
              className="action-icon"
              onClick={() => navigate(`/company/${r.id}/edit`, { state: r })}
            />
            <Popconfirm
              title="Are you sure?"
              onConfirm={_delete_company.bind(this, r)}
            >
              <DeleteFilled className="action-icon error" />
            </Popconfirm>
          </Space>
        ),
      },
    ];
  }, [_delete_company, navigate]);

  return (
    <Layout.Content>
      <Typography.Title level={3}>Companies</Typography.Title>

      <Table
        columns={columns}
        dataSource={companies}
        loading={spinning}
        rowKey="id"
      />

      <Button
        icon={<PlusOutlined />}
        type="primary"
        className="fab"
        shape="circle"
        onClick={() => navigate("/company/new")}
      />
    </Layout.Content>
  );
}

export default CompaniesList;

import {
  Button,
  Col,
  DatePicker,
  Layout,
  message,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import React, { useCallback, useMemo, useState } from "react";
import moment from "moment";
import { api, axios_error } from "../../../config";

function OrdersList() {
  const [orders, setOrders] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [loading, setLoading] = useState(false);
  const [companiesFilter, setCompaniesFilter] = useState([]);
  const [vendorsFilter, setVendorsFilter] = useState([]);

  const disabledDate = useCallback(
    (current) => {
      if (!dateRange) {
        return false;
      }
      const tooLate = dateRange[0] && current.diff(dateRange[0], "days") > 7;
      const tooEarly = dateRange[1] && dateRange[1].diff(current, "days") > 7;
      return !!tooEarly || !!tooLate;
    },
    [dateRange]
  );

  const __get_filters = useCallback((data) => {
    let _companies = [],
      c = [],
      _vendors = [],
      v = [];
    data.forEach((item) => {
      if (!v.includes(item.vendor_id)) {
        _vendors.push({ text: item.vendor, value: item.vendor_id });
        v.push(item.vendor_id);
      }
      if (!c.includes(item.company_id)) {
        _companies.push({ text: item.company, value: item.company_id });
        c.push(item.company_id);
      }
      setCompaniesFilter(_companies);
      setVendorsFilter(_vendors);
    });
  }, []);

  const __get_orders_list = useCallback(() => {
    if (
      !(
        Array.isArray(dateRange) &&
        moment.isMoment(dateRange[0]) &&
        moment.isMoment(dateRange[1])
      )
    ) {
      message.error("Please select date range!");
      return;
    }
    setLoading(true);
    api
      .get(
        `/company/orders?start_date=${dateRange[0].format(
          "YYYY-MM-DD"
        )}&end_date=${dateRange[1].format("YYYY-MM-DD")}`
      )
      .then((res) => {
        setOrders(res.data);
        __get_filters(res.data);
        setLoading(false);
      })
      .catch((err) => {
        axios_error(err);
        setLoading(false);
      });
  }, [dateRange, __get_filters]);

  const columns = useMemo(() => {
    return [
      { title: "ID", dataIndex: "id" },
      { title: "Date", dataIndex: "date" },
      {
        title: "Variant",
        dataIndex: "variant",
        filters: [
          { text: "Breakfast", value: "BREAKFAST" },
          { text: "Lunch", value: "LUNCH" },
          { text: "Snacks", value: "SNACKS" },
          { text: "Dinner", value: "DINNER" },
        ],
        onFilter: (value, record) => record.variant.indexOf(value) === 0,
        sorter: (a, b) => a.variant.length - b.variant.length,
      },
      { title: "Product", dataIndex: "product" },
      {
        title: "Vendor",
        dataIndex: "vendor",
        filters: vendorsFilter,
        onFilter: (value, record) => record.vendor_id === value,
        sorter: (a, b) => a.vendor_id - b.vendor_id,
      },
      {
        title: "Company",
        dataIndex: "company",
        filters: companiesFilter,
        onFilter: (value, record) => record.company_id === value,
        sorter: (a, b) => a.company_id - b.company_id,
      },
      { title: "Customer", dataIndex: "customer" },
      { title: "Orderd on", dataIndex: "timestamp" },
    ];
  }, [companiesFilter, vendorsFilter]);

  return (
    <Layout.Content>
      <Row gutter={16}>
        <Col md={12} sm={24}>
          <Typography.Title level={3}>Orders List</Typography.Title>
        </Col>
        <Col md={12} sm={24}>
          <Space>
            <DatePicker.RangePicker
              value={dateRange}
              onCalendarChange={setDateRange}
              onChange={setDateRange}
              disabledDate={disabledDate}
            />
            <Button onClick={__get_orders_list}>Go</Button>
          </Space>
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={orders}
        loading={loading}
        rowKey="id"
      />
    </Layout.Content>
  );
}

export default OrdersList;

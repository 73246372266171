import React from "react";
import { Modal, Typography } from "antd";

export default function TicketViewerModal({ visible, close, ticket }) {
  return (
    <Modal
      title="Support Ticket Details"
      visible={visible}
      onCancel={() => close()}
    >
      <div style={{ marginBottom: 16 }}>
        <Typography.Title level={4} style={{ marginBottom: 0 }}>
          Title
        </Typography.Title>
        <Typography.Paragraph>{ticket?.title}</Typography.Paragraph>
      </div>
      <div style={{ marginBottom: 16 }}>
        <Typography.Title level={5} style={{ marginBottom: 0 }}>
          Timestamp
        </Typography.Title>
        <Typography.Paragraph>{ticket?.timestamp}</Typography.Paragraph>
      </div>
      <div style={{ marginBottom: 16 }}>
        <Typography.Title level={4} style={{ marginBottom: 0 }}>
          Details
        </Typography.Title>
        <Typography.Paragraph>{ticket?.description}</Typography.Paragraph>
      </div>
      <div style={{ marginBottom: 16 }}>
        <Typography.Title level={4} style={{ marginBottom: 0 }}>
          Reply <small>{ticket?.reply_timestamp}</small>
        </Typography.Title>
        <Typography.Paragraph>{ticket?.reply || " - "}</Typography.Paragraph>
      </div>
    </Modal>
  );
}

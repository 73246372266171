import React, { useCallback, useState } from "react";
import { Form, Modal } from "antd";
import { VendorPicker } from "../../../components/pickers";
import { api, axios_error } from "../../../config";

const FormItem = Form.Item;

function CompanyVendorForm({ visible, close, company_id }) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);
      let val = await form.validateFields();
      await api.post(
        `/admin/company/${company_id}/vendor`,
        JSON.stringify(val)
      );
      setLoading(false);
      form.resetFields();
      close(true);
    } catch (err) {
      setLoading(false);
      axios_error(err);
    }
  }, [form, close, company_id]);

  return (
    <Modal
      title="Company Vendor Form"
      visible={visible}
      onOk={handleSubmit}
      onCancel={close.bind(this, false)}
      confirmLoading={loading}
    >
      <Form layout="vertical" form={form}>
        <FormItem
          label="Vendor"
          name="vendor_id"
          rules={[{ required: true, message: "Vendor is required!" }]}
        >
          <VendorPicker />
        </FormItem>
      </Form>
    </Modal>
  );
}

export default CompanyVendorForm;

import {
  PauseCircleFilled,
  PlayCircleFilled,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Layout, Space, Table, Typography } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { api, axios_error } from "../../../config";
import CompanyUserPlanForm from "./CompanyUserPlanForm";

function CompanyUserPlan() {
  const [plans, setPlans] = useState([]);
  const [spinning, setSpinning] = useState(false);
  const [modalForm, showModalForm] = useState(false);

  const { userId } = useParams();

  const _get_company_user_plans = useCallback(() => {
    setSpinning(true);
    api
      .get(`/company/user/${userId}/plan`)
      .then((res) => {
        setPlans(res.data);
        setSpinning(false);
      })
      .catch((err) => {
        axios_error(err);
        setSpinning(false);
      });
  }, [userId]);

  useEffect(() => {
    _get_company_user_plans();
  }, [_get_company_user_plans]);

  const closeModal = useCallback(
    (refresh) => {
      if (refresh) {
        _get_company_user_plans();
      }
      showModalForm(false);
    },
    [_get_company_user_plans]
  );

  const columns = useMemo(() => {
    return [
      { title: "ID", dataIndex: "id" },
      { title: "Weekday", dataIndex: "weekday" },
      { title: "Variant", dataIndex: "variant" },
      {
        title: "Product Type",
        dataIndex: "product_type",
        render: (v) => <span>{v || " - "}</span>,
      },
      {
        title: "Available",
        dataIndex: "available",
        render: (v) => <span>{v ? "Yes" : "No"}</span>,
      },
      {
        title: "Actions",
        render: (r) => (
          <Space>
            {!r.available ? (
              <PlayCircleFilled className="action-icon" />
            ) : (
              <PauseCircleFilled className="action-icon" />
            )}
          </Space>
        ),
      },
    ];
  }, []);

  return (
    <Layout.Content>
      <Typography.Title level={3}>User Food Preferences</Typography.Title>

      <CompanyUserPlanForm
        visible={modalForm}
        close={closeModal}
        userId={userId}
      />

      <Table columns={columns} dataSource={plans} loading={spinning} />

      <Button
        icon={<PlusOutlined />}
        type="primary"
        shape="circle"
        className="fab"
        onClick={() => showModalForm(true)}
      />
    </Layout.Content>
  );
}

export default CompanyUserPlan;

import { Layout, Typography } from "antd";
import React from "react";
import { Route, Routes } from "react-router";
import { Login } from "../pages/guest";

const { Header, Footer, Content } = Layout;

function GuestLayout() {
  return (
    <Layout>
      <Header
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography.Title level={1} style={{ margin: 0, color: "white" }}>
          BizEat
        </Typography.Title>
      </Header>
      <Content style={{ minHeight: "60vh", padding: 32 }}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="*" element={<Login />} />
        </Routes>
      </Content>
      <Footer style={{ backgroundColor: "#1f1f1f" }}>
        <p style={{ margin: 0, textAlign: "center", color: "white" }}>
          &copy; BizEat
        </p>
      </Footer>
    </Layout>
  );
}

export default GuestLayout;

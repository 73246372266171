import React, { useCallback, useEffect, useState } from "react";
import { Form, Input, message, Modal } from "antd";
import { CompanyUserRolePicker } from "../../../components/pickers";
import { api, axios_error } from "../../../config";

const FormItem = Form.Item;

function CompanyUserForm({ visible, close, company_id, user }) {
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);
      let val = await form.validateFields();
      if (user?.id) {
        await api.put(
          `/admin/company/${company_id}/user/${user?.id}`,
          JSON.stringify(val)
        );
        message.success("User info updated!");
      } else {
        await api.post(
          `/admin/company/${company_id}/user`,
          JSON.stringify(val)
        );
        message.success("User Added!");
      }
      form.resetFields();
      setLoading(false);
      close(true);
    } catch (err) {
      axios_error(err);
      setLoading(false);
    }
  }, [form, close, company_id, user]);

  useEffect(() => {
    if (visible && user?.id) {
      form.setFieldsValue(user);
    }
  }, [visible, user, form]);

  return (
    <Modal
      title="Company User"
      visible={visible}
      onOk={handleSubmit}
      onCancel={close.bind(this, false)}
      confirmLoading={loading}
    >
      <Form layout="vertical" form={form}>
        <FormItem label="User's Full Name" name="name">
          <Input />
        </FormItem>
        <FormItem label="Mobile" name="mobile">
          <Input />
        </FormItem>
        <FormItem
          label="Email"
          name="email"
          rules={[
            { required: true, message: "Email is required!" },
            { type: "email", message: "Enter a valid email address!" },
          ]}
        >
          <Input />
        </FormItem>
        <FormItem
          label="Employee ID"
          name="employee_id"
          rules={[{ required: true, message: "Employee ID is required!" }]}
        >
          <Input />
        </FormItem>
        <FormItem
          label="Company User Role"
          name="company_user_role"
          rules={[
            { required: true, message: "Company user role is required!" },
          ]}
        >
          <CompanyUserRolePicker />
        </FormItem>
      </Form>
    </Modal>
  );
}

export default CompanyUserForm;

import { Layout, Table, Typography } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { api, axios_error } from "../../../config";

function ProductsList() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const _get_products_list = useCallback(() => {
    setLoading(true);
    api
      .get("/company/products")
      .then((res) => {
        setProducts(res.data);
        setLoading(false);
      })
      .catch((err) => {
        axios_error(err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    _get_products_list();
  }, [_get_products_list]);

  const columns = useMemo(() => {
    return [
      { title: "ID", dataIndex: "id" },
      { title: "Name", dataIndex: "name" },
      { title: "Code", dataIndex: "code" },
      { title: "Product Type", dataIndex: "product_type" },
    ];
  }, []);

  return (
    <Layout.Content>
      <Typography.Title level={3}>Products List</Typography.Title>

      <Table columns={columns} dataSource={products} loading={loading} />
    </Layout.Content>
  );
}

export default ProductsList;

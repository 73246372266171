import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Layout,
  Typography,
  Table,
  Space,
  Button,
  Popconfirm,
  message,
} from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { api, axios_error } from "../../../config";
import CategoryForm from "./CategoryForm";

export default function CategoriesList() {
  const [categories, setCategories] = useState([]);
  const [spinning, setSpinning] = useState(false);
  const [showFormModal, setShowFormModal] = useState(false);
  const [cat, setCat] = useState({});

  const _get_categories = useCallback(() => {
    setSpinning(true);
    api
      .get("/admin/categories")
      .then((res) => {
        setCategories(res.data);
        setSpinning(false);
      })
      .catch((err) => {
        axios_error(err);
        setSpinning(false);
      });
  }, []);

  useEffect(() => {
    _get_categories();
  }, [_get_categories]);

  const _delete_category = useCallback(
    (r) => {
      api
        .delete(`/admin/category/${r.id}`)
        .then((_res) => {
          message.success("Category deleted");
          _get_categories();
        })
        .catch((err) => {
          axios_error(err);
        });
    },
    [_get_categories]
  );

  const columns = useMemo(() => {
    return [
      { title: "Id", dataIndex: "id" },
      { title: "Name", dataIndex: "name" },
      {
        title: "Actions",
        render: (r) => (
          <Space>
            <EditOutlined
              className="action-icon"
              onClick={() => {
                setCat(r);
                setShowFormModal(true);
              }}
            />
            <Popconfirm
              title="Are you sure?"
              onConfirm={_delete_category.bind(this, r)}
            >
              <DeleteOutlined className="action-icon" />
            </Popconfirm>
          </Space>
        ),
      },
    ];
  }, [_delete_category]);

  const closeModal = (refresh = false) => {
    if (refresh) {
      _get_categories();
    }
    setShowFormModal(false);
    setCat({});
  };

  return (
    <Layout.Content>
      <Typography.Title>Categories</Typography.Title>

      <Table
        columns={columns}
        dataSource={categories}
        loading={spinning}
        rowKey="id"
      />

      <Button
        type="primary"
        shape="circle"
        className="fab"
        icon={<PlusOutlined />}
        onClick={() => setShowFormModal(true)}
      />

      <CategoryForm visible={showFormModal} close={closeModal} category={cat} />
    </Layout.Content>
  );
}
